import {Box, Button, Checkbox, Chip, Divider, List, ListItem, ListItemButton, ListItemText, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import {capitaliseWords, camelCaseToNormal, InstituteData, OrganisationAddress, CohortData, SUCCESSTEXTCOLOR, ERRORTEXTCOLOR, INSTITUTE_COLOUR, BillingPackage} from "placementt-core";
import SubNavBar, {SubNavBarItems} from "../../../Components/Navigation/SubNavBar";
import Page from "../../../Components/Page";
import {InfoTableCell} from "../../../Util/styledComponents";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../App";
import Card from "../../../Components/Card";
import Profile from "../../Shared/Profile";
import Resources from "../../Shared/Resources";
import {Popup} from "../../../Components/Popup";
import {LoadingButton} from "../../../Components/LoadingButton";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import FilterList from "../../../Components/FilterList";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Delete, Edit} from "@mui/icons-material";
import DeletePopup from "../../../Components/DeletePopup";
import AddAddressPopup from "../../Providers/Popups/AddAddressPopup";
import {ProfilePic} from "../../Providers/Home";
import BillingTable from "../../../Components/BillingTable";


export default function Organisation() {
    const items:SubNavBarItems = [
        {
            label: "Overview",
            route: "overview",
            element: <Overview/>,
        },
        {
            label: "Profile",
            route: "profile",
            element: <Profile/>,
        },
        {
            label: "Resources",
            route: "resources",
            element: <Resources/>,
        },
        {
            label: "Billing",
            route: "billing",
            element: <Billing/>,
        },
    ];
    return (<SubNavBar items={items}/>);
}

function Overview() {
    const [delPopupActive, setDelPopupActive] = useState(false);
    const [delPopupError, setDelPopupError] = useState("");
    const [deleteAddress, setDeleteAddress] = useState<{id: string, address: OrganisationAddress}>();

    const [addressPopup, setAddressPopup] = useState<{id: string, address: OrganisationAddress}|boolean>(false);

    const orgContext = useContext(OrganisationContext);
    const institute = orgContext.details as InstituteData;
    const cohorts = orgContext.cohorts as {[key: string]: CohortData};
    const addresses = orgContext.addresses as {[key: string]: OrganisationAddress};


    const firebaseQuery = new FirebaseQuery();

    const onDeleteAddress = async () => {
        if (!deleteAddress) return;

        if (Object.values(cohorts).filter((cohort) => cohort.addressId === deleteAddress.id).length > 0) {
            setDelPopupError("Cannot delete an address with active cohorts.");
        }
        if (deleteAddress.address.default) {
            setDelPopupError("Can't delete an address set as your default address. Set another address as default first.");
        }

        await firebaseQuery.delete(["addresses", deleteAddress.id]);

        setDelPopupActive(false);
        setDeleteAddress(undefined);
    };

    return (
        <Page
            title="Overview"
            metaTitle="Setup | Overview"
            grid>
            <Card grid xs={12} md={5} title={"Organisation details"} contentSx={{overflow: "hidden"}}>
                <Stack direction={"row"} spacing={10}>
                    <Box height={"120px"} width={"120px"}>
                        <ProfilePic/>
                    </Box>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <InfoTableCell>Institute</InfoTableCell>
                                <InfoTableCell>{institute.name}</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell colSpan={2}><Button color="error">Delete Placementt - Institute account</Button></InfoTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </Card>
            <FilterList title="Addresses" actionButton={institute.package === "institutes-two" && <Button onClick={() => setAddressPopup(true)}>Add address</Button>} data={addresses} grid xs={12} md={7}>
                {Object.entries(addresses).map(([id, address]) =>
                    <ListItemButton key={id} divider id={id}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <ListItemText
                                primary={<Stack spacing={0}>
                                    <Typography fontSize={"1.2em"} pb={1}>{address.name}</Typography>
                                    <Typography color={"grey"}>{address["address-line1"]} | {address.postal_code} | {capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                                    <Typography>Associated cohorts: {Object.values(cohorts).filter((cohort) => cohort.addressId === id).length}</Typography>
                                </Stack>}/>
                            <Stack spacing={1}>
                                <Stack direction={"row"}>
                                    <Chip sx={{width: "max-content", alignSelf: "end"}} color={address.stage !== "complete" ? "warning" : "primary"} label={address.stage !== "complete" ? "Complete set up" : "Active"}/>
                                    {Boolean(address?.flags?.length) && address.flags?.map((flag) => <Chip sx={{width: "max-content", alignSelf: "end"}} color="warning" label={capitaliseWords(camelCaseToNormal(flag))}/>)}
                                    {address.default && <Chip sx={{width: "max-content", alignSelf: "end"}} color="primary" label={"Default"}/>}
                                </Stack>
                                <Stack direction={"row"} sx={{justifyContent: "flex-end"}}>
                                    <IconButtonPop responsive={false} title="Edit address" onClick={() => setAddressPopup({id: id, address: address})}><Edit/></IconButtonPop>
                                    {(Object.values(cohorts).filter((cohort) => cohort.addressId === id).length > 0) || <IconButtonPop responsive={false} onClick={async (e) => {
                                        e.stopPropagation();
                                        setDeleteAddress({id: id, address: address});
                                        setDelPopupActive(true);
                                    }} title="Delete address"><Delete/></IconButtonPop>}
                                </Stack>
                            </Stack>
                        </Stack>
                    </ListItemButton>)}
            </FilterList>
            <DeletePopup
                open={delPopupActive}
                onClose={() => {
                    setDelPopupActive(false); setDeleteAddress(undefined); setDelPopupError("");
                }}
                error={delPopupError}
                title={"address"}
                itemName={deleteAddress?.address?.name}
                // For deleting workflow form or attachment, need to make sure no workflow is using hte attachment. Just add a popup for this as nonessential.
                onDelete={async () => await onDeleteAddress()}/>
            {institute.package === "institutes-two" && <AddAddressPopup key={addressPopup.toString()} onSubmit={() => setAddressPopup(false)} onToggle={() => setAddressPopup(false)} active={Boolean(addressPopup)} addressId={typeof addressPopup === "object" ? addressPopup.id : undefined} initialAddress={typeof addressPopup === "object" ? addressPopup.address : undefined}/>}
        </Page>);
}


function Billing() {
    const [updatePlanPopup, setUpdatePlanPopup] = useState(false);
    const [currentPackage, setCurrentPackage] = useState<BillingPackage>();


    const [addOnPopup, setAddOnPopup] = useState(false);

    const orgDetails = useContext(OrganisationContext).details as InstituteData;
    const user = useContext(UserContext);

    const [selectedPackage, setSelectedPackage] = useState(orgDetails.package);
    const [selectedAddOnPackages, setSelectedAddOnPackages] = useState(orgDetails.addOnPackages);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        (firebaseQuery.getDocData(["billing", orgDetails.package as string]) as Promise<BillingPackage>).then(setCurrentPackage);
    }, [orgDetails.package]);


    return (
        <Page
            title="Billing"
            metaTitle="Setup | Billing"
            grid>
            <Card grid sm={12} md={6} title={"Your Placementt plan"} secondaryTitle={<Button onClick={() => setUpdatePlanPopup(true)}>Update plan</Button>}>
                <Divider sx={{borderColor: INSTITUTE_COLOUR, mb: 2}}/>
                <Typography variant="h6" color={INSTITUTE_COLOUR}>{currentPackage?.name}</Typography>
                <Typography variant="subtitle2" sx={{opacity: 0.7}}>{currentPackage?.description}</Typography>
                <Table size="small">
                    <TableRow>
                        <InfoTableCell>Price / staff / year</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.staffUnitCost}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Price / student / year</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.studentUnitCost}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Price / placement / month</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.placementUnitCost}</InfoTableCell>
                    </TableRow>
                </Table>
                <Stack>
                    <Divider/>
                    <Typography variant="h6">Split billing</Typography>
                    <Typography sx={{opacity: 0.7}}>Split billing across your cohorts, so we invoice them all individually. This can be useful if managing multiple schools. You can still see the invoices, but you can designate staff members in each cohort.</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Split billing: <strong style={{color: orgDetails.splitBilling ? SUCCESSTEXTCOLOR : ERRORTEXTCOLOR}}>{orgDetails.splitBilling ? "Active" : "Inactive"}</strong></Typography>
                        <Box sx={{flex: 1, borderBottom: "1px solid lightgrey"}}/>
                        <LoadingButton noFlexGrow onClick={async () => firebaseQuery.update(["institutes", user.oId], {splitBilling: !orgDetails.splitBilling})} text={orgDetails.splitBilling ? "Disable" : "Enable"}/>
                    </Stack>
                </Stack>
            </Card>
            <Card grid sm={12} md={6} title={"Add-Ons"} secondaryTitle={<Button onClick={() => setAddOnPopup(true)}>Update add-ons</Button>}>
                <Typography>Add-on packages to help you support your students.</Typography>
                <List>
                    {orgDetails.addOnPackages?.map((addOn) =>
                        <ListItem divider>
                            <ListItemText primary={addOn.toUpperCase()} secondary={"Manage work experience evidencing as part of your work experience process and automate collection of placement details and signoff from employers."}/>
                        </ListItem>)}
                </List>
            </Card>
            <Card grid sm={12} md={6} title={"Invoices"}>

            </Card>

            <Popup title={"Update your plan"} fullWidth open={updatePlanPopup} onClose={() => setUpdatePlanPopup(false)}>
                <Stack>
                    <BillingTable product={user.product} {...{selectedPackage, setSelectedPackage}}/>
                    <LoadingButton variant="contained" onClick={async () => user.oId ? firebaseQuery.update(["institutes", user.oId], {package: selectedPackage}) : new Error("NO ID")} text="Update package"/>
                </Stack>
            </Popup>
            <Popup title={"Configure add-ons"} fullWidth open={addOnPopup} onClose={() => setAddOnPopup(false)}>
                <Stack>
                    <List>
                        <ListItem divider>
                            <ListItemText primary={<Stack direction={"row"} alignItems={"center"}>
                                <Checkbox
                                    checked={selectedAddOnPackages?.includes("admissions")}
                                    onChange={() => setSelectedAddOnPackages((oldPackages) => {
                                        if (!oldPackages) return ["admissions"];
                                        if (!oldPackages?.includes("admissions")) {
                                            return [...oldPackages, "admissions"];
                                        }
                                        return oldPackages.filter((i) => i !== "admissions");
                                    })}
                                    name="radio-buttons"
                                    inputProps={{"aria-label": "admissions"}}
                                />
                                <Typography>Admissions add-on</Typography>
                            </Stack>} secondary={"Manage work experience evidencing as part of your work experience process and automate collection of placement details and signoff from employers."}/>
                        </ListItem>
                    </List>
                    <LoadingButton variant="contained" onClick={async () => orgDetails.id ? firebaseQuery.update(["institutes", orgDetails.id], {addOnPackages: selectedAddOnPackages}) : new Error("NO ID")} text="Update add-ons"/>
                </Stack>
            </Popup>
        </Page>);
}
