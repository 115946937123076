import {Alert, Button, Chip, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import Page from "../../../Components/Page";
import FilterTable from "../../../Components/FilterTable";
import {Popup} from "../../../Components/Popup";
import {useContext, useEffect, useState} from "react";
import {InstituteData, InstitutePlacementListingUpload, PRIMARY_COLOUR, PlacementListing, QueryObject, SavedPlacement, camelCaseToNormal, capitaliseWords, countryList, executeCallable, getRandomNumber, storage, useInstitutePlacementListingHandler, usePlacementListingPaginator} from "placementt-core";
import SubNavBar, {SubNavBarItems} from "../../../Components/Navigation/SubNavBar";
import SpreadsheetUploader from "../../../Components/SpreadsheetUploader";
import {OrganisationContext, UserContext} from "../../../App";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import ControlledSwitch from "../../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import {InfoTableCell} from "../../../Util/styledComponents";
import {ProviderAddress, ProviderGuidance} from "../../Shared/ViewPlacement";
import DeletePopup from "../../../Components/DeletePopup";
import {deleteField, where} from "firebase/firestore";
import PlacementMap from "../../Shared/PlacementMap";
import TutorialPage from "../../Shared/TutorialPage";
import {getDownloadURL, ref} from "firebase/storage";
import {useNavigate} from "react-router-dom";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Card from "../../../Components/Card";
import {LoadingButton} from "../../../Components/LoadingButton";
import Form from "../../../Components/Form";
// import PlacementMap from "../../Shared/PlacementMap";


export default function PlacementsNew() {
    const subNavItems:SubNavBarItems = [{
        label: "List",
        route: "list",
        element: <PlacementsList/>,
    },
    {
        label: "Map",
        route: "map",
        element: <PlacementsMap/>,
    }];


    return (
        <Page
            title="Placements"
            subtitle={"View and upload placements available for your students to search."}
            noGutters
            fullHeight>
            <TutorialPage pageId="instituteStaffPlacements"/>
            <SubNavBar items={subNavItems}/>
        </Page>);
}

function PlacementsList() {
    const [popupActive, setPopupActive] = useState(false);
    const [viewPlacementPopup, setViewPlacementPopup] = useState<PlacementListing&{savedPlacement: SavedPlacement}|false>(false);
    const [viewPlacementContent, setViewPlacementContent] = useState<PlacementListing&{savedPlacement: SavedPlacement}>();
    const [externalProviderUploadPopup, setExternalProviderUploadPopup] = useState(false);
    const [externalProviderUploadCopied, setExternalProviderUploadCopied] = useState(false);
    const [deletePlacementPopup, setDeletePlacementPopup] = useState<PlacementListing|false>(false);
    const [selectedPlacements, setSelectedPlacements] = useState<"All"|{[key: string]: PlacementListing&{savedPlacement: SavedPlacement}}>();
    const [placementTableKey, setPlacementTableKey] = useState(getRandomNumber(0, 100000));

    const instituteDetails = useContext(OrganisationContext).details as InstituteData;

    const [allowExternalProviderUploads, setAllowExternalProviderUploads] = useState(instituteDetails.externalProviderUploads);

    const [totalPlacementCount, setTotalPlacementCount] = useState<number>();
    const [activePlacementCount, setActivePlacementCount] = useState<number>();

    const user = useContext(UserContext);

    const {uploadPlacements, alert} = useInstitutePlacementListingHandler({...{user}});

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (viewPlacementPopup) {
            setViewPlacementContent(viewPlacementPopup);
            return;
        }
    }, [viewPlacementPopup]);

    useEffect(() => {
        firebaseQuery.getCount(["savedPlacements"], [where("savedById", "==", user.oId), where("savedByProduct", "==", user.product)]).then(setTotalPlacementCount);
        firebaseQuery.getCount(["savedPlacements"], [where("savedById", "==", user.oId), where("savedByProduct", "==", user.product), where("listed", "==", true)]).then(setActivePlacementCount);
    }, []);


    const updateExternalProviderUploads = async () => {
        return await firebaseQuery.update(["institutes", user.oId], {externalProviderUploads: !instituteDetails.externalProviderUploads});
    };

    const toggleVisibility = async (visibility: "show"|"hide") => {
        if (!selectedPlacements) return;
        console.log("SEC", selectedPlacements);

        if (selectedPlacements !== "All") {
            await Promise.all(Object.keys(selectedPlacements).map(async (savedPlacement) => {
                return await firebaseQuery.update(["savedPlacements", savedPlacement], {listed: visibility === "show"});
            }));
            setPlacementTableKey(getRandomNumber(0, 10000));
            return;
        }

        await firebaseQuery.getDocsWhere("savedPlacements", [where("savedById", "==", user.oId), where("savedByProduct", "==", user.product)]).then(async (docs) => {
            const placements = docs as {[key: string]: SavedPlacement};
            await Promise.all(Object.keys(placements).map(async (savedPlacement) => {
                return await firebaseQuery.update(["savedPlacements", savedPlacement], {listed: visibility === "show"});
            }));
            setPlacementTableKey(getRandomNumber(0, 10000));
        });
    };

    const resendProviderEmails = async () => {
        await executeCallable("placementListing-resendInstituteEmails", {items: selectedPlacements});

        return {severity: "success", message: "Reminder sent."};
    };

    return (
        <Page grid>
            <Grid xs={12} md={8} item>
                <FilterTable
                    columns={["name", "title", "email", "uploadedBy", "status", "listed"]}
                    columnTemplate={{
                        name: (name: string) => <strong>{name}</strong>,
                        uploadedBy: (name: string) => <Chip label={capitaliseWords(name)}/>,
                        status: (name: string) => <Chip variant="filled" color={name === "active" ? "success" : name === "public" ? "primary" : name === "requiresApproval" ? "warning" : undefined} label={capitaliseWords(camelCaseToNormal(name))}/>,
                        listed: (value:boolean, row: PlacementListing&{savedPlacement: SavedPlacement}) => <Chip color={row.savedPlacement.listed ? "primary" : undefined} label={row.savedPlacement.listed ? "True" : "False"}/>}}
                    viewRows
                    onItemClick={(_, row) => setViewPlacementPopup(row as PlacementListing&{savedPlacement: SavedPlacement})}
                    hook={(data) => usePlacementListingPaginator({data: data as QueryObject[], user: user})}
                    actionButtonText={"Add placements"}
                    key={placementTableKey}
                    onActionButtonClick={() => setPopupActive(true)}
                    setSelectedRows={(e) => setSelectedPlacements(e as "All"|{[key:string]: PlacementListing&{savedPlacement: SavedPlacement}})}>
                    <IconButtonPop onClick={() => toggleVisibility("show")} title="Show to students"><Visibility/></IconButtonPop>
                    <IconButtonPop onClick={() => toggleVisibility("hide")} title="Hide from students"><VisibilityOff/></IconButtonPop>
                    <LoadingButton sx={{display: "inline-block !important"}} noFlexGrow onClick={async () => await resendProviderEmails()} text="Resend provider emails"/>
                </FilterTable>
            </Grid>
            <Grid xs={12} md={4} item>
                <Stack>
                    <Card title={<Typography variant={"h5"}>Overview</Typography>}>
                        <List>
                            <ListItem sx={{marginBottom: "20px"}}>
                                <ListItemText primaryTypographyProps={{fontWeight: "bold"}} sx={{marginRight: 2.5}} primary="Allow external business uploads"/>
                                <ListItemSecondaryAction>
                                    {!allowExternalProviderUploads ? <ControlledSwitch name="externalProviderConsent" onCheck={() => setExternalProviderUploadPopup(true)}/> : <Button onClick={() => setExternalProviderUploadPopup(true)}>View</Button>}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                                <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Total"/>
                                <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                                    {totalPlacementCount}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Active"/>
                                <ListItemSecondaryAction>
                                    {activePlacementCount}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Reported"/>
                                <ListItemSecondaryAction>
                                    0
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px"}}>
                                <ListItemText primary="Blocked"/>
                                <ListItemSecondaryAction>
                                    0
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Card>
                </Stack>
            </Grid>
            <Popup maxWidth={"lg"} open={popupActive} onClose={() => setPopupActive(false)} title={"Add placement"}>
                <SpreadsheetUploader
                    cellTypes={{country: ["dropdown", countryList, "unitedKingdom"]}}
                    alert={alert}
                    noNewColumns
                    onSubmit={(e) => uploadPlacements(e as InstitutePlacementListingUpload[]).then((e) => e && setPopupActive(false))}
                    defaultCols={["provider", "jobTitle", "email", "phone", "addressOne", "addressTwo", "city", "postcode", "country"]}/>
            </Popup>
            {false && <DeletePopup open={Boolean(deletePlacementPopup)} onClose={() => setDeletePlacementPopup(false)} title={"placement"} onDelete={async () => console.log("a")}>
                <Typography>Blocking this placement will prevent your users from seeing it on their placement map.</Typography>
            </DeletePopup>}
            <PlacementListingDetailsPopup reloadTable={() => setPlacementTableKey(getRandomNumber(0, 10000))} open={Boolean(viewPlacementPopup)} onClose={() => setViewPlacementPopup(false)} placement={viewPlacementContent} onDeletePopupToggled={() => setDeletePlacementPopup(viewPlacementContent || false)}/>
            <Popup open={externalProviderUploadPopup} onClose={() => {
                setExternalProviderUploadPopup(false);
                setExternalProviderUploadCopied(false);
            }} title={"External placement uploads"}>
                <Stack>
                    <Typography>Allow businesses to advertise their available placements by sharing the link below.</Typography>
                    <Typography>Businesses can follow this link to placement details that students will see on your interactive placement map. We will also verify the provider's employers liability insurance.</Typography>
                    {allowExternalProviderUploads && <Stack direction={"row"} spacing={0} alignItems={"center"}>
                        <InputGroup sx={{color: `${PRIMARY_COLOUR} !important`, pointerEvents: "none"}} value={`https://placementt.co.uk/submitPlacement/${user.oId}`}/>
                        <Button sx={{height: "40px !important", borderRadius: "0px 4px 4px 0px", marginLeft: "-10px", background: "white !important"}} onClick={() => {
                            navigator.clipboard.writeText(`https://placementt.co.uk/submitPlacement/${user.oId}`);
                            setExternalProviderUploadCopied(true);
                        }}>{externalProviderUploadCopied ? "Coped!" : "Copy"}</Button>
                    </Stack>}
                    <LoadingButton color={allowExternalProviderUploads ? "error" : "primary"} onClick={() => updateExternalProviderUploads().then(() => {
                        setExternalProviderUploadPopup(!allowExternalProviderUploads && true);
                        setAllowExternalProviderUploads((a) => !a);
                        setExternalProviderUploadCopied(false);
                    })} text={allowExternalProviderUploads ? "Disable provider submissions" : "Enable provider submissions"}/>
                </Stack>
            </Popup>
        </Page>);
}

export function PlacementListingDetailsPopup({open, onClose, placement, onDeletePopupToggled, reloadTable}:{open: boolean, onClose: () => void, placement?: PlacementListing&{savedPlacement?: SavedPlacement, insurance?:boolean}, onApprove?: () => Promise<void>, onDeletePopupToggled?: () => void, reloadTable?: () => void}) {
    const institute = useContext(OrganisationContext).details as InstituteData;
    const [rejectELIPopup, setRejectELIPopup] = useState(false);
    const [eliPopupOpen, setEliPopupOpen] = useState(false);
    const [eliURL, setELIURL] = useState<string>();
    const [concurrentPopupOpen, setConcurrentPopupOpen] = useState(false);
    const [fPlacement, setFPlacement] = useState<PlacementListing&{savedPlacement?: SavedPlacement}|undefined>();

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();


    useEffect(() => {
        setFPlacement(placement);
        if (user.userType !== "Staff") return;
        const storageRef = ref(storage, `insurance/${placement?.providerId}.pdf`);
        getDownloadURL(storageRef).then((file) => {
            setELIURL(file);
        }).catch(() => {
            setELIURL(undefined);
        });
    }, [placement]);

    const toggleVisibility = async () => {
        if (!fPlacement?.savedPlacement?.id) return;
        await firebaseQuery.update(["savedPlacements", fPlacement?.savedPlacement.id], {listed: !fPlacement?.savedPlacement?.listed});
        setFPlacement((p) => {
            if (!p) return;
            const newP = {...p};
            if (!newP.savedPlacement) return newP;

            newP.savedPlacement.listed = !newP.savedPlacement.listed;
            return newP;
        });
        reloadTable && reloadTable();
        return;
    };

    const setConcurrency = async (e: {concurrentPlacements: number}) => {
        if (!fPlacement?.savedPlacement?.id) return;
        await firebaseQuery.update(["savedPlacements", fPlacement?.savedPlacement.id], {concurrentPlacements: e.concurrentPlacements});
        setFPlacement((p) => {
            if (!p) return;
            const newP = {...p};
            if (!newP.savedPlacement) return newP;

            newP.savedPlacement.concurrentPlacements = e.concurrentPlacements;
            return newP;
        });
        return;
    };

    const clearConcurrency = async () => {
        if (!fPlacement?.savedPlacement?.id) return;
        await firebaseQuery.update(["savedPlacements", fPlacement?.savedPlacement.id], {concurrentPlacements: deleteField()});
        setFPlacement((p) => {
            if (!p) return;
            const newP = {...p};
            if (!newP.savedPlacement) return newP;

            newP.savedPlacement.concurrentPlacements = undefined;
            return newP;
        });
    };

    const acceptELI = async () => {
        if (user.userType !== "Staff") return;
        await executeCallable("insurance-approve", {providerId: fPlacement?.providerId, placementId: fPlacement?.id});
        setRejectELIPopup(false);
        setEliPopupOpen(false);
    };

    const rejectELI = async ({reason}:{reason: string}) => {
        if (user.userType !== "Staff") return;
        await executeCallable("insurance-reject", {reason: reason, placement: fPlacement, instituteName: institute.name});
        setRejectELIPopup(false);
        setEliPopupOpen(false);
    };

    console.log("FPLE", fPlacement);

    return (
        <>
            <Popup
                title={"View placement"}
                {...{open, onClose}}
                fullWidth
                actions={
                    <Stack width={"100%"}>
                        {user.userType === "Staff" && eliURL && !institute.verifiedProviders?.includes(fPlacement?.providerId || "") &&
                        <>
                            <Typography variant="h6" textAlign={"center"}>Action required</Typography>
                            <Typography>Review the insurance for this placement to approve and list to your students. </Typography>
                            <Button sx={{alignSelf: "center"}} variant="contained" onClick={() => setEliPopupOpen(true)}>Review insurance</Button>
                        </>
                        }
                        {user.userType === "Students" && <>
                            <Alert severity="info" color="primary">Create a placement from this listing only if you have contacted and received confirmation from this employer.</Alert>
                            <Button sx={{alignSelf: "center"}} variant="contained" onClick={() => navigate(`/${user.product}/create`, {state: {placement: {...fPlacement, placementId: fPlacement?.id, id: undefined}}})}>Create placement</Button>

                        </>}

                        {false && <Stack direction={"row"} justifyContent={"end"}>
                            {onDeletePopupToggled && <Button color="error" onClick={onDeletePopupToggled}>Block placement</Button>}
                        </Stack>}
                    </Stack>}>
                {fPlacement && <>
                    <Stack>
                        {/* user.userType === "Staff" && placement.insurance === "Verifying" && <Alert severity='info' action={<Button size={'small'} color={'inherit'} onClick={() => setEliPopupOpen(true)}>View</Button>}>Verify Employer's liability insurance.</Alert>*/}
                        <Table size="small" sx={{maxWidth: "550px", zIndex: 1}}>
                            <TableBody>
                                <TableRow>
                                    <InfoTableCell>Provider name</InfoTableCell>
                                    <InfoTableCell>{fPlacement.name}</InfoTableCell>
                                    <InfoTableCell></InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell>Job title</InfoTableCell>
                                    <InfoTableCell>{fPlacement.title}</InfoTableCell>
                                    <InfoTableCell></InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell>Provider email</InfoTableCell>
                                    <InfoTableCell>{fPlacement.providerEmail}</InfoTableCell>
                                    <InfoTableCell></InfoTableCell>
                                </TableRow>
                                {user.userType === "Staff" && <TableRow>
                                    <InfoTableCell>Listed to students</InfoTableCell>
                                    <InfoTableCell><Chip color={fPlacement?.savedPlacement?.listed ? "primary" : undefined} label={fPlacement?.savedPlacement?.listed ? "True" : "False"}/></InfoTableCell>
                                    <InfoTableCell><LoadingButton onClick={toggleVisibility} text={fPlacement?.savedPlacement?.listed ? "Hide" : "Show"}/></InfoTableCell>
                                </TableRow>}
                                {user.userType === "Staff" && <TableRow>
                                    <InfoTableCell>Max concurrent placements</InfoTableCell>
                                    <InfoTableCell>{fPlacement.savedPlacement?.concurrentPlacements || "Unset"}</InfoTableCell>
                                    <InfoTableCell><Button onClick={() => setConcurrentPopupOpen(true)}>Change</Button></InfoTableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                        <Divider sx={{marginBottom: "5px"}} />
                        <Popup title={"Maximum concurrent placements"} open={concurrentPopupOpen} onClose={() => setConcurrentPopupOpen(false)}>
                            <Stack>
                                <Typography>Set the maximum number of placements that can be active at one time.</Typography>
                                <Typography variant="caption">Note, this is a preliminary feature, so will limit all placements currently in the workflow, regardless of date. If you would like us to improve this feature, please get in touch.</Typography>
                                <Form onSubmit={(e) => setConcurrency(e as {concurrentPlacements: number})}>
                                    <InputGroup type={"number"} value={fPlacement.savedPlacement?.concurrentPlacements} name={"concurrentPlacements"} label={"Max placements"} min={0} required/>
                                </Form>
                                <LoadingButton onClick={clearConcurrency} text="Clear" variant="outlined" color={"inherit"}/>
                            </Stack>
                        </Popup>
                    </Stack>
                    {Boolean(fPlacement.contactType) && <Alert severity="info">To apply for this placement, {
                        fPlacement.contactType === "phone" ? `phone ${fPlacement.providerPhone}` :
                            fPlacement.contactType === "post" ? "send your application by post" :
                                fPlacement.contactType === "website" && fPlacement.webpage ? `go to ${fPlacement.webpage}` :
                                    `email ${fPlacement.providerEmail}`
                    }</Alert>}
                    <ProviderAddress placement={fPlacement}/>
                    {fPlacement.questions && <ProviderGuidance guidance={fPlacement.questions}/>}
                </>}
            </Popup>
            {user.userType === "Staff" && <><Popup
                open={eliPopupOpen}
                onClose={() => setEliPopupOpen(false)}
                maxWidth={false}
                cardSx={{maxWidth: "700px", width: "calc(100vw - 10px)"}}
                title={"Employer's Liability Insurance"}
                actions={
                    <LoadingButton width='max-content'>
                        <Button variant='contained' onClick={acceptELI}>Accept</Button>
                        <Button color='error' onClick={async () => {
                            setRejectELIPopup(true); return;
                        }}>Reject</Button>
                    </LoadingButton>
                }>
                <object data={eliURL} type="application/pdf" width="100%" height="500px">
                    <p>Error loading insurance. Please reload.</p>
                </object>
            </Popup>
            <Popup title={"Reject insurance"} open={rejectELIPopup} onClose={() => setRejectELIPopup(false)}>
                <Typography>Rejecting this employers liability insurance will require them to reupload. You can check back here once it is checked.</Typography>
                <Form onSubmit={async (e) => {
                    rejectELI(e as {reason:string}); return;
                }}>
                    <InputGroup label="Rejection reason" name={"reason"} required/>
                </Form>
            </Popup></>}
        </>
    );
}


function PlacementsMap() {
    return (
        <Page fullHeight noGutters>
            <PlacementMap fullscreen={false} title="Search placements" type="internal"/>
        </Page>);
}
