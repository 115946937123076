import {ListItem, ListItemText, Stack, Chip, ListItemSecondaryAction, Typography, Button} from "@mui/material";
import {QueryConstraint, where} from "firebase/firestore";
import {CohortData, defaultStudentWorkflow, ERRORTEXTCOLOR, StudentPlacementData, useNewInstitutePlacementList, UserData, WorkflowStage} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {OrganisationContext, UserContext} from "../App";
import {RenderPlacementFlags} from "../Util/visualUtil";
import FilterList from "./FilterList";
import useWindowDimensions from "../Util/util";
import FilterTable, {FilterObject} from "./FilterTable";

// uid only provided if own placements. Provided on student dashboard

type Params = {
    id?: string,
    title: string,
    sx?: {[key:string]: unknown},
    queryConstraint?: QueryConstraint[],
    filters?: {[key:string]: unknown},
    urlRef?: string
    cohort?: CohortData|"all",
    inProgress?: boolean,
    viewType?: "list"|"table"
}


export default function PlacementList({id, inProgress, title, sx, queryConstraint, filters, urlRef, cohort, viewType="list"}:Params) {
    const [renderedPlacements, setRenderedPlacements] = useState<(false | JSX.Element)[]>();

    const {width} = useWindowDimensions();
    const navigate = useNavigate();

    const user = useContext(UserContext) as UserData;
    const cohorts = useContext(OrganisationContext)?.cohorts;

    const filterNames: FilterObject = {
        reqUserType: {
            label: "Required user",
            type: "dropdown",
            values: {
                Staff: "Staff",
                Students: "Student",
                Provider: "Provider",
                Parent: "Parent/Guardian",
            },
            value: filters?.["reqUserType"],
        },
        riskAssessment: {
            label: "Risk assessement",
            values: {awaitingReview: "Requires review", true: "Verified", false: "Not uploaded"},
            type: "dropdown",
            value: ["true", "false"].includes(filters?.["riskAssessment"] as string) ? filters?.["riskAssessment"] === "true" : filters?.["riskAssessment"],
        },
        dbsCheck: {
            label: "DBS check",
            values: {awaitingReview: "Requires review", true: "Verified", false: "Not uploaded"},
            type: "dropdown",
            value: ["true", "false"].includes(filters?.["dbsCheck"] as string) ? filters?.["dbsCheck"] === "true" : filters?.["dbsCheck"],
        },
        insurance: {
            label: "Insurance",
            values: {awaitingReview: "Requires review", true: "Verified", false: "Not uploaded"},
            type: "dropdown",
            value: ["true", "false"].includes(filters?.["insurance"] as string) ? filters?.["insurance"] === "true" : filters?.["insurance"],
        },
    };

    if (cohort !== "all") {
        filterNames["status" as keyof typeof filterNames] = {
            label: "Status",
            type: "dropdown",
            values: Object.fromEntries(cohort?.workflow.map((stage) => [stage.id, stage.name]) || []),
            value: filters?.["status"],
        };
    }

    useEffect(() => {
        console.log("FILTERS", filters);
        // const newConstraints = Object.entries(filterNames).filter(([, item]) => item.value !== undefined).map(([name, item]) => where(name, "==", parseInt(item.value as string) || item.value));

        const newNewConstraints = Object.entries(filters || {}).filter(([key, value]) => key !== "id" && value !== undefined).map(([key, value]) => {
            if (filterNames[key]) {
                return where(key, "==", parseInt(filterNames[key].value as string) || filterNames[key].value);
            } else {
                return where(key, "==", ["true", "false"].includes(value as string) ? value === "true" : parseInt(value as string) || value);
            }
        });

        console.log("ACTUAL", filterNames.insurance.value);
        console.log("NC", newNewConstraints);
        changeQueryConstraints(newNewConstraints);
    }, [filters]);


    const {placements, loadMoreIcon, loadMorePlacements, setQuery, changeQueryConstraints} = useNewInstitutePlacementList({...{id, user, queryConstraint, inProgress}, cohort: (cohort ? cohort === "all" ? undefined : cohort.id : undefined)});

    useEffect(() => {
        const renderPlacements = async () => {
            if (!placements) return;
            setRenderedPlacements((await Promise.all(Object.entries(placements as {[key:string]:StudentPlacementData&{student:UserData}}).map(async ([key, item]) => {
                const workflow = item.cohort ? cohorts[item.cohort]?.workflow : defaultStudentWorkflow;
                const status = item.status;

                const wStage = workflow?.find((obj:WorkflowStage) => obj.id === status);

                const viewPlacementLocation = item.student.product === "students" ? `/${user.product}/placements/${key}` : user.userType === "Staff" ? `/${user.product}/cohorts/placements/${key}` : `../placements/${key}`;

                return (
                    <ListItem button key={key} divider id={key} onClick={() => navigate(viewPlacementLocation)}>
                        <ListItemText
                            primary={<Stack spacing={0}>
                                {width <= 500 && wStage &&
                                    <Stack height={"100%"} direction={"row"} sx={{justifyContent: "space-between"}}>
                                        <Chip label={item.statusType === "withdrawn" ? "Withdrawn" : wStage.name} color={item.statusType === "withdrawn" ? "error" : "primary"} sx={{width: "max-content", margin: "8px !important"}}/>
                                        {item.student && <RenderPlacementFlags placement={item} student={item.student} workflow={workflow} icon/>}</Stack>}
                                <strong>{user.id === item.uid ? item.name : (item.student ? `${item.student.details.forename} ${item.student.details.surname}` : <span style={{color: ERRORTEXTCOLOR}}>Student deleted</span>)}</strong>
                                {user.id === item.uid || <span>{item.name}</span>}
                                <span>{item["address-line1"]} - {item.locality} - {item.postal_code}</span>

                            </Stack>}
                            secondary={`${item.startDate} - ${item.endDate}`}/>
                        {width > 500 && wStage && <ListItemSecondaryAction sx={{height: "100%"}}>
                            <Stack height={"100%"} direction={"row"} sx={{justifyContent: "space-between"}}>
                                <Chip label={item.statusType === "withdrawn" ? "Withdrawn" : wStage.name} color={item.statusType === "withdrawn" ? "error" : "primary"} sx={{width: "max-content", margin: "8px"}}/>
                                {item.student && <RenderPlacementFlags placement={item} student={item.student} workflow={workflow} icon/>}
                            </Stack>
                        </ListItemSecondaryAction>}
                    </ListItem>
                );
            }))).filter((x) => x));
        };
        renderPlacements();
    }, [placements, width]);

    if (viewType === "table") {
        return (<FilterTable data={placements} columns={["name", "student.details.forename"]}/>);
    }

    return (
        <FilterList
            key={id+"placementList"}
            noSearch={!inProgress}
            filters={filterNames}
            actionButton={user.userType === "Staff" && inProgress && <Button onClick={() => navigate(`/${user.product}/create`)}>Add placement</Button>}
            {...{title, sx, loadMoreIcon, urlRef}}
            noResultsText={user.userType === "Students" ? <Typography sx={{textWrap: "balance"}}>No placements found. <Link to={`/${user.product}/create`} style={{textDecoration: "underline"}}>Click here</Link> to create one or click "Create" in the sidebar.</Typography> : undefined}
            onSearch={(e) => {
                setQuery(e);
            }} data={placements} onScrollBottom={() => {
                console.log("LOAD MORE PLEASE");
                loadMorePlacements();
            }}>
            {renderedPlacements}
        </FilterList>
    );
}
