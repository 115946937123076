import {KeyboardArrowLeftRounded, KeyboardArrowRightRounded} from "@mui/icons-material";
import {Button, Checkbox, ListItem, ListItemText, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {Children, Dispatch, cloneElement, createRef, isValidElement, useEffect, useState} from "react";
import IconButtonPop from "./IconButtonPop";
import {RenderFlags} from "../Util/visualUtil";
import {camelCaseToNormal, FlagCodes, QueryObject, useFilterTablePaginator} from "placementt-core";
import FilterSelector from "./FilterSelector";
import {useSearchParams} from "react-router-dom";
import {QueryFieldFilterConstraint} from "firebase/firestore";
import {SearchBox} from "./SearchBox";

// editCols must be a link to the collection where to alter docs

export type FilterObject = {
    [key: string]: {
        label: string,
        value?: unknown,
        values?: {[key:string|number]: string|{label: string, test: QueryFieldFilterConstraint}},
        type?: "dropdown"|string
    }
}

type FilterTableParams = {
    title?: string,
    data?: {[key:string]: unknown}|QueryObject[],
    columns: string[],
    columnTemplate?: {[key:string]:unknown},
    children?: React.ReactNode,
    setSelectedRows?: (e:unknown) => void,
    setTableFilters?: (e:{[key: string]: unknown; }) => void,
    onActionButtonClick?: (e:unknown) => void,
    actionButtonText?: string,
    formatQueryData?: (e:unknown) => void,
    onItemClick?: (id:string, row?: {[key:string]: unknown}) => void,
    filters?: FilterObject,
    access?: boolean,
    /* filters?: {
        name:string,
        label:string,
        type:string|[string|number, string|number][]
    }[], */
    urlRef?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hook?: (data: {
        [key: string]: {[key:string]: unknown};
    } | QueryObject[], query?: string) => HookParams,
    viewRows?: boolean,
    disableSelection?: boolean,
    search?: boolean
}

type HookParams = {
    tableData: {[key: string]: {[key: string]: unknown}},
    setFilters: Dispatch<React.SetStateAction<{[key: string]: unknown} | undefined>>,
    setPage: Dispatch<React.SetStateAction<number[]>>,
    page: number[]
}

export default function FilterTable({title, search, data = {}, hook= (data) => useFilterTablePaginator({data: data}), disableSelection, setTableFilters, columns, columnTemplate={}, children, setSelectedRows, actionButtonText, onActionButtonClick, formatQueryData, filters, viewRows, onItemClick, urlRef, access=true}:FilterTableParams) {
    const [selected, setSelected] = useState<{[key: string]: {[key: string]: unknown}}>();
    const [allSelected, setAllSelected] = useState(false);
    const [query, setQuery] = useState<string>();

    const {tableData, setFilters, setPage, page}:HookParams = hook(data as any, query);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fFilters = Object.fromEntries([...searchParams]);
        const filterElementId = fFilters.id;
        delete fFilters.id;
        if (filterElementId !== urlRef) return;
        setFilters(Object.fromEntries(Object.entries(fFilters).map(([name, value]) => {
            const filter = filters?.[name];

            if (!filter || filter.type !== "dropdown") return [name, value];

            const valueType = filter.values?.[value];

            if (!valueType || typeof valueType === "string") return [name, value];


            return [name, valueType.test];
        })));
        setTableFilters && setTableFilters(fFilters);
    }, [searchParams]);

    const tableContainerScrollRef = createRef<HTMLDivElement>();

    useEffect(() => {
        setSelected(undefined);
        setAllSelected(false);
        setSelectedRows && setSelectedRows({});
        setPage([1, 0]);
    }, [setFilters]);

    const pageUp = () => {
        setPage((p) => ([p[0]+1, p[0]]));
    };

    const pageDown = () => {
        setPage((p) => ([p[0]-1, p[0]]));
    };

    useEffect(() => {
        if (!tableContainerScrollRef.current) return;
        tableContainerScrollRef.current.scrollTo(0, 0);
    }, [tableData]);

    // Update in parent component
    useEffect(() => {
        if (allSelected) {
            setSelectedRows && setSelectedRows("All");
            return;
        }
        if (setSelectedRows && selected) {
            setSelectedRows(selected);
        }
    }, [selected, setAllSelected]);

    const setAllSelectedHandler = () => {
        if (allSelected) {
            setSelected({});
            setAllSelected(false);
        } else {
            setSelected(tableData);
            setAllSelected(true);
        }
    };

    console.log("render", tableData);

    return (
        <Box sx={{maxHeight: "100%", display: "flex", flexFlow: "column", position: "relative"}}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{flex: "0 1 auto"}}>
                <Stack direction={"row"} alignItems={"center"}>
                    {title && <Typography variant='h6' width={"max-content"} display={"inline-block"} mr={2}>{title}</Typography>}
                    {Children.count(children) > 0 && <Box sx={{display: "inline", position: "relative", marginLeft: "8px"}}>
                        <Typography position={"absolute"} top={"50%"} sx={{translate: "0 -50%", width: "max-content", opacity: selected && Object.keys(selected).length ? 0 : 1, transition: "opacity 100ms ease-in-out"}} variant="subtitle2" color={"#0000006d"} display={"inline-block"}>Select an item to view options</Typography>
                        {Children.map(children, (child) => {
                            if (!isValidElement(child)) {
                                return child;
                            }

                            const props = {sx: {opacity: (selected && Object.keys(selected).length) ? 1 : 0, transition: "opacity 100ms ease-in-out"}};
                            return cloneElement(child, props);
                        })}
                    </Box>}
                </Stack>
                <Stack direction={"row"} spacing={1} sx={{float: "right"}} >
                    {search && <SearchBox placeholder={"Search"} onSearch={(q) => setQuery(q)}/>}
                    {filters && <FilterSelector filters={filters} urlRef={urlRef}/>}
                    {actionButtonText && <Button onClick={onActionButtonClick} variant="contained">{actionButtonText}</Button>}
                </Stack>
            </Stack>
            <TableContainer ref={tableContainerScrollRef} style={{flex: "1 1 auto", marginBottom: "24px"}}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {disableSelection || <TableCell style={{padding: 0}}><Checkbox checked={allSelected} onClick={() => setAllSelectedHandler()}/></TableCell>}
                            {Object.entries(tableData).find(([, v]:[string, unknown]) => (v as {[key:string]:unknown}).flags) && <TableCell style={{padding: 0}}></TableCell>}
                            {columns.map((value) => {
                                return <TableCell style={{padding: 0}} key={value}>
                                    <ListItem disablePadding style={{paddingLeft: 0}}>
                                        <ListItemText primary={camelCaseToNormal(value)}/>
                                    </ListItem>
                                </TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries((formatQueryData ? formatQueryData(tableData) : tableData) as {[key: string]: { [key: string]: unknown }}).map(([key, row]) => {
                            return (
                                <TableRow key={key} onClick={() => viewRows && onItemClick && onItemClick(key, row)} sx={(viewRows && onItemClick) ? {":hover": {background: "#00000012"}, "cursor": "pointer"} : {}}>
                                    {disableSelection || <TableCell style={{padding: 0}}>
                                        <Checkbox checked={Boolean(allSelected || (selected && Object.prototype.hasOwnProperty.call(selected, key)))}
                                            onClick={(e) => {
                                                selected && Object.prototype.hasOwnProperty.call(selected, key) ? setSelected((s) => s ? Object.fromEntries(Object.entries(s).filter(([k]) => k !== key)) : undefined) : setSelected({...selected, [key]: row});
                                                e.stopPropagation();
                                            }}/>
                                    </TableCell>}
                                    {Object.entries(tableData).find(([, v]:[string, unknown]) => (v as {[key:string]:unknown}).flags) && <TableCell style={{padding: 0}}>
                                        {(row?.flags as Array<unknown>)?.length > 0 && <RenderFlags flags={row.flags as FlagCodes[]} icon/>}
                                    </TableCell>}
                                    {columns.map((value) => {
                                        const colValue = (columnTemplate[value] && (columnTemplate[value] as (e:unknown, row: unknown) => string)(row[value], row)) || row[value] as string;

                                        return <TableCell>{colValue as string}</TableCell>;
                                    })}
                                </TableRow>
                            );
                        })}
                        {Object.keys(tableData).length === 0 &&
                            <TableRow>
                                <TableCell colSpan={columns.length + 2} sx={{textAlign: "center", opacity: 0.7}}>
                                    {access ? "No data to display." : "You don't have access to this. Contact your administrator to update access in your user group."}
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
                <Stack direction='row' justifyContent={"space-between"} alignItems='center' borderBottom={"rgba(0, 0, 0, 0.1) solid 0.2px"}>
                    {disableSelection || <Typography color={"rgba(0, 0, 0, 0.5);"} fontSize={"0.8em"}>Selected: {allSelected ? <span style={{color: "red"}}>All pages</span> : selected ? Object.keys(selected).length : 0}</Typography>}
                    <Box>
                        <IconButtonPop key={"backButtn"} responsive={false} disabled={Boolean(page[0] === 1)} title="Previous page" onClick={pageDown}><KeyboardArrowLeftRounded/></IconButtonPop>
                        <IconButtonPop key={"forwardButton"} responsive={false} disabled={Boolean(Object.keys(tableData).length < 10)} title="Next page" onClick={pageUp}><KeyboardArrowRightRounded/></IconButtonPop>
                    </Box>
                </Stack>
            </TableContainer>
        </Box>
    );
}
